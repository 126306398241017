import React from 'react'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/layout'
import CallToActionGeneric from '../components/callToActionGeneric'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.contentfulPost
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const metaImage = post.featuredImage.ogimg.src

    return (
      <Layout location={this.props.location}>
        <Helmet>
          <title itemProp="name" lang="en">{`${post.title} | ${siteTitle}`}</title>
          <meta name="description" content={`${post.summary}`} />
          
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@mohragency" />
          <meta name="twitter:title" content={`${post.title}`} />
          <meta name="twitter:description" content={`${post.summary}`} />  
          <meta property="twitter:image" content={metaImage} /> 

          <meta property="og:title" content={`${post.title}`} />
          <meta property="og:type" content="article" />
          <meta property="og:description" content={`${post.summary}`} /> 
          <meta property="og:image" content={metaImage} /> 
          <meta property="og:site_name" content="Lewis Mohr Insurance Agency" />
        </Helmet>
        <Img className="mainImage blogHeaderImage" sizes={post.featuredImage.fluid} alt="Insurance Blog Header Image" />
        <div className="site-body">
          <div className="grid grid-gutters grid-justifyCenter">
            <div className="grid-cell">               
              <div className="blogTitle singleBlog">          
                <h1>{post.title}</h1>
                <h6>{post.date}</h6>
              </div>
              <div className="blogContent" dangerouslySetInnerHTML={{ __html: post.details.childMarkdownRemark.html }} />
            </div>
          </div>
        </div>
        <CallToActionGeneric bg={this.props.data.callToActionBg} />
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-28.jpg" }) {
      ...mainBg
    }
    contentfulPost(slug: {eq: $slug}) {
      title
      slug
      date(formatString: "MMMM D, YYYY")
      summary
      featuredImage {
        fluid(maxWidth: 1800, sizes: "") {
          aspectRatio
          sizes
          src
          srcSet
        }
        ogimg: resize(width: 1800) {
          src
        }
      }
      details {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
